<template>

  <div class="bStyle">

    <div class="titlecss" style="margin-bottom: 20px;">{{internalizationRedirect.redirectTitle}}</div>

    <img alt="logo" src="@/assets/logo-open-banking.png" height="40">

    <hr style="width:95%; margin-top: 20px;">

    <div class="detail" style='text-align: center'>{{internalizationRedirect.redirectMsg1}}</div>

    <div style='text-align: center'>
      <TecbanRedirectCardShadow
        :pstyle='cardstyle'
        :cardLabel="ifChoose"
      />
    </div>

    <div class="wrapperline">
      <div class="wrapperbox">
        <div class="wrappedicon"><span><LoadFillTecbanIcon ref="loadFill"/></span></div>
        <div class="wrappedtext font"><span>{{internalizationRedirect.redirectMsg2}}</span></div>
      </div>
    </div>

    <div style='text-align: center'>
      <TecbanRedirectCardShadow
        :pstyle='cardstyle'
        cardLabel="iniciadora"
      />
    </div>

  </div>

  <div id="modalTimeOut" style="display:none;z-index: 1000;position: relative;">
    <ModalAlwaysShow
      tipo='error'
      :messageErr = internalizationRedirect.redirectErrTimeOutLabel
      :detailErr = internalizationRedirect.redirectErrTimeOutMsg
    />
  </div>

  <div id="modalFetchException" style="display:none;z-index: 1000;position: relative;">
    <ModalAlwaysShow
      tipo='error'
      :messageErr = internalizationRedirect.redirectErrFetchLabel
      :detailErr = internalizationRedirect.redirectErrFetchMsg
    />
  </div>

  <div id="modalDataException" style="display:none;z-index: 1000;position: relative;">
    <ModalAlwaysShow
      tipo='error'
      :messageErr = internalizationRedirect.redirectErrValidatingDataLabel
      :detailErr = internalizationRedirect.redirectErrValidatingDataMsg
    />
  </div>

  <div id="modalCommunicationLost" style="display:none;z-index: 1000;position: relative;">
    <ModalAlwaysShow
      tipo='error'
      :messageErr= internalizationRedirect.redirectErrCommunicationLabel
      :detailErr = internalizationRedirect.redirectErrCommunicationMsg
    />
  </div>

  <LoadingModal id="redirectLoadingModalId" ref="redirectLoadingModal"/>
  
</template>

<script>
import { getWithExpiry, getTheme, getInternalization, endSess } from '@/util/util.js';

var payload = null;
var requestFor = null;
var creationDateTime = null;
var circle = null;

/* variables used to rotate the circle */
var minTime = 1000;   // minimum is 10, to minimize time to load
var actualPercent = 0;
/* */

/* Request time out controllers */
var didTimeOut = false;
var showExceptionModel = false;
const FETCH_TIMEOUT = 30000;
/* */

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

/* the circle goes from 200(empty) to 70(full) */
async function rotateCircle(percent){
  
  let starts = 200 - (actualPercent/100)*(130);
  let ends = starts - (percent/100)*(130);
  let interval = starts - ends;

  let minTimeTimeAdjust = minTime/10;

  for(let i=1; i <= minTimeTimeAdjust; i++){
    await sleep(10);
    circle.style.strokeDashoffset = starts - ((i/(minTimeTimeAdjust))*interval);
  }
  actualPercent = (actualPercent + percent >= 100 ? actualPercent+percent - 100 : actualPercent+percent);
}
/* */

var isEmpty = function(text){

  if (text === 'undefined' || text == null) 
    return true;

  if (typeof text == 'string')
    return text.replace(/\s/g, '').length < 1;
  
  return false;
};

/* eslint-disable */
export default {
  data() {
    return {
      
      ifChoose: '',
      cardstyle:{
        'display': 'inline-block',
        'min-width': '90%',
        'max-width': '90%',
        'min-height': 'auto',
        'max-height': 'auto'
      }
    }
  },
  methods: {
    
    /* util */
    async timeoutFunction() {
      await sleep(FETCH_TIMEOUT);
      didTimeOut = true;
      return 'Request timed out';
    },
    checkOnline(){
      if(!navigator.onLine){
        endSess('dados');
        document.getElementById('modalCommunicationLost').style.display = 'block';
      }
    },
    getExpirationDateTime(){

      // 60 minutes in milisseconds = 60*60*1000
      var addTime = 3600000;
      
      var dataExp = new Date((new Date(creationDateTime)).getTime() + addTime);
      var dataExpF = dataExp.toISOString();

      return dataExpF;
    },
    /* */

    /* requests */
    async getConsentToken() {

      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      try{

        if(didTimeOut)
          throw 'Request timed out';
        let response = await fetch(process.env.VUE_APP_CONSENTS+'/consent/consent-token', requestOptions);

        if(didTimeOut)
          throw 'Request timed out';
        let token = await response.json();

        return token;
      }
      catch(err){
        console.log('get bearer token error');
        console.log(err);
        return 'get bearer token error';
      }
    },
    async patchChangeAccount(status, bToken, useExpiration){

      let consentId = payload.consent_id;
      let userId = payload.sub;
      let accountsIds = [(this.$route.params.hasOwnProperty('accountId') ? this.$route.params.accountId : payload.account_id)];
      let expirationDT = this.getExpirationDateTime();
      let dataNow = (new Date()).toISOString();

      let useBearerToken = process.env.VUE_APP_USE_BEARER != "0";

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let rawTmp = {};

      rawTmp["psuIdentifiers"] = {"userId": userId};

      if (useBearerToken)
        rawTmp["bankConnectToken"] = bToken.token_type + " " +  bToken.access_token;

      rawTmp["accountIds"] = accountsIds;
      rawTmp["consentBody.data.status"] = status;

      if (useExpiration){
        rawTmp["consentBody.data.expirationDateTime"] = expirationDT;
        rawTmp["consentBody.data.statusUpdateDateTime"] = dataNow;
      }

      var raw = JSON.stringify(rawTmp);

      var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      try{
        if(didTimeOut)
          throw 'Request timed out';
        let response = await fetch(process.env.VUE_APP_CONSENTS+"/consent/change-account/" + consentId, requestOptions);

        if (!response.ok) {
          throw 'patch status error ' + response.status;
        }

        return response;
      }
      catch(error){
        console.log('patch error');
        console.log(error);
        return 'patch error';
      }
    },
    async postConfirmConsent(){

      let consentId = payload.consent_id;
      let interactionId = payload.interaction_id;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("heimdall.suppressRefreshToken", "false");
      urlencoded.append("openbanking_intent_id", consentId);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      try{
        if(didTimeOut)
          throw 'Request timed out';
        let response = await fetch(process.env.VUE_APP_CONSENTS+'/consent/consent-confirm/' + interactionId, requestOptions);

        if (!response.ok)
          throw 'doConfirm status error ' + response.status;

        let url = await response.text();

        return url;
      }
      catch(error){
        console.log('doConfirm error');
        console.log(error);
        return 'doConfirm error';
      }
    },
    async postCancelConsent(){
      
      let consentId = payload.consent_id;
      let interactionId = payload.interaction_id;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("heimdall.suppressRefreshToken", "false");
      urlencoded.append("openbanking_intent_id", consentId);
      urlencoded.append("error", "access_denied");

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      try{
        if(didTimeOut)
          throw 'Request timed out';
        let response = await fetch(process.env.VUE_APP_CONSENTS+'/consent/consent-fail/' + interactionId, requestOptions);
        
        if (!response.ok)
          throw 'doFail status error ' + response.status;

        let url = await response.text();

        return url;
      }
      catch(error){
        console.log('doFail error');
        console.log(error);
        return 'doFail error';
      }
    },
    /* */

    async confirmConsent(){

      document.getElementById('redirectLoadingModalId').style.display = "none";

      if(sessionStorage.getItem("TIMEOUT_SIMULATION_CT23") === 'true'){
        console.log('TIMEOUT_SIMULATION_CT23');
        await sleep(FETCH_TIMEOUT);
      }

      // get bearer token
      let bToken = null;
      if (process.env.VUE_APP_USE_BEARER != "0"){
        bToken = await this.getConsentToken();
        if (bToken == 'get bearer token error'){
          throw bToken;
        }
      }
        
      // patch
      let patchResponse = await this.patchChangeAccount('AUTHORISED', bToken, true);
      if (patchResponse == 'patch error')
        throw patchResponse;
      await rotateCircle(50);
      
      // doConfirm
      let doConfirmResponseUrl = await this.postConfirmConsent();
      if (doConfirmResponseUrl == 'doConfirm error')
        throw doConfirmResponseUrl;
      await rotateCircle(50);
      
      if (isEmpty(doConfirmResponseUrl))
        doConfirmResponseUrl = "https://openbankingbrasil.org.br/";

      window.location.href = doConfirmResponseUrl;
    },
    async cancelConsent(){

      document.getElementById('redirectLoadingModalId').style.display = "none";

      if(sessionStorage.getItem("TIMEOUT_SIMULATION_CT23") === 'true'){
        console.log('TIMEOUT_SIMULATION_CT23');
        await sleep(FETCH_TIMEOUT);
      }

      // get bearer token
      let bToken = null;
      if (process.env.VUE_APP_USE_BEARER != "0"){
        bToken = await this.getConsentToken();
        if (bToken == 'get bearer token error'){
          throw bToken;
        }
      }
        
      // patch
      let patchResponse = await this.patchChangeAccount('REJECTED', bToken, false);
      if (typeof patchResponse == 'patch error')
        throw patchResponse;
      await rotateCircle(50);

      // doFail
      let doFailResponseUrl = await this.postCancelConsent();
      if (doFailResponseUrl == 'doFail error')
        throw doFailResponseUrl;
      await rotateCircle(50);
      
      if (isEmpty(doFailResponseUrl))
        doFailResponseUrl = "https://openbankingbrasil.org.br/";

      window.location.href = doFailResponseUrl;
    },
  },
  async created() {
    this.ifChoose = getTheme();
    this.$root.$refs.mainNavbar.hideGoBackButton();

    this.internalizationRedirect = getInternalization();
  },
  async mounted(){

    document.getElementById('redirectLoadingModalId').style.display = "block";

    setInterval(() => {
      this.checkOnline();
    }, 3000);

    payload = JSON.parse(getWithExpiry('dados'));
    requestFor = (this.$route.params.hasOwnProperty('requestFor') ? this.$route.params.requestFor : '');
    circle = document.getElementById('fillCircle');

    if(!payload){
      this.$root.$refs.loadingModal.disable();
      this.$root.$refs.exceptionWarnigFetchingData.enable();
    }

    try{

      if(requestFor != 'confirm' && requestFor != 'cancel'){
        this.$router.go(-1);
      }
      else{
        
        if (this.$route.params.hasOwnProperty('creationDateTime'))
          creationDateTime = this.$route.params.creationDateTime;
        else
          throw 'creationDateTime not found';

        if(requestFor == 'confirm'){
          let response = await Promise.race([this.timeoutFunction(),this.confirmConsent()]);
          if(response == 'Request timed out')
            throw response;
        }
        else if(requestFor == 'cancel'){
          let response = await Promise.race([this.timeoutFunction(),this.cancelConsent()]);
          if(response == 'Request timed out')
            throw response;
        }
        
      }
    }
    catch(error){
      
      console.log(error);

      try{
        this.patchChangeAccount('AWAITING_AUTHORISATION', null, false);
      }
      catch(error2){}

      if (!showExceptionModel){
        showExceptionModel = true;
        if(error == 'patch error' || error == 'get bearer token error' || error == 'doConfirm error' || error == 'doFail error')
          document.getElementById('modalFetchException').style.display = 'block';
        else if(error == 'Request timed out')
          document.getElementById('modalTimeOut').style.display = 'block';
        else
          document.getElementById('modalDataException').style.display = 'block';
      }

    }
  }
}
</script>

<style scoped>

/* Centralizando o loadIcon e sua mensagem de forma responsiva*/
.wrapperline{
  display: block;
  text-align: center;
}
.wrapperbox{
  display: inline-block;
  width: 85%;
  text-align: center;
}
.wrapperbox .wrappedicon, .wrapperbox .wrappedtext{
  display: inline-block;
  height: 100%;
}
.wrapperbox .wrappedicon{
  width: 50px;
}
.wrapperbox .wrappedtext{
  min-width: 0px;
  max-width: 80%;
}
.wrapperbox .wrappedicon span, .wrapperbox .wrappedtext span{
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  word-wrap: normal;
}
.wrapperbox .wrappedicon{
  text-align: right;
}
.wrapperbox .wrappedtext{
  text-align: left;
  margin-left: 10px;
}
.wrapperbox .wrappedtext span{
  text-align: center;
}
/* */

.bStyle{
  height: 100vh;
  background-color: var(--page-background-color);
  padding: 17px 23px 25px 24px;
}
.titlecss{
  margin-top: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  color: var(--redirect-main-title-color);
}
.detail{
  line-height: 24px;
  margin-bottom: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 2rem;
  padding-right: 2rem;
}
.detail, .font{
  font-size: 16px;
  color: var(--redirect-text-color);
}
hr{
  background-color: var(--hrline-color);
  border: 0;
  clear:both;
  display:block;
  width: 100%;
  height: 1px;
  margin-top:0;
  margin-bottom: 10px;
}
</style>